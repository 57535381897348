import React from "react";
import { Layout } from "../components/Layout";

function ErrorPage() {
  return (
    <div style={{ alignItems: "center", display: "flex", height: "60vh" }}>
      <p className="titleText" style={{ flexGrow: 1 }}>
        This page does not exist!
      </p>
    </div>
  );
}

ErrorPage.Layout = Layout;

export default ErrorPage;
